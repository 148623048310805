/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'calendar3-range-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M2 0a2 2 0 00-2 2h16a2 2 0 00-2-2zM0 8V3h16v2h-6a1 1 0 100 2h6v7a2 2 0 01-2 2H2a2 2 0 01-2-2v-4h6a1 1 0 100-2z"/>',
    },
});
